﻿* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input {
    -webkit-user-select: auto !important;
    user-select: auto !important;
}

.win-listview .win-viewport {
    -ms-overflow-style: scrollbar !important;
} 


@font-face {
    font-family: "ISL fontfamily";
    src: url("../../Content/isl.ttf") format("truetype"), url("../../Content/isl.eot"), url("../../Content/isl.eot#iefix") format("embedded-opentype");
}

i[class*="win-icon-custom"]:before {
    font-family: "ISL fontfamily";
}

.win-icon-custom-isl:before {
    content: "\E110";
}


.win-listitem {
    padding: 10px;
    width: 100%;
    min-height: 50px;
    /*overflow: hidden;*/
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.win-listitem.win-listitem-filled {
    background-color: rgba(0, 0, 0, 0.10);
}

.win-listitem .win-listview-item-button {
    position: absolute;
    right:0;
    top:0;
}

.win-listitem .win-listview-item-detail {
}

.win-large-dialog .win-contentdialog-dialog {
    max-width:none !important;
    width: 80%;

    margin-left: auto;
    margin-right: auto;

    padding:40px 40px;

    .win-contentdialog-primarycommand {
        
    }
}

.win-large-dialog .win-contentdialog-dialog .win-contentdialog-title {
    padding:20px;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-container {
    padding: 10px;
    /*display: none;*/
}

.loader-small {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto;
}

.loader-medium {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto;
}

.loader-large {
    border: 20px solid #f3f3f3;
    border-radius: 50%;
    border-top: 20px solid #3498db;
    width: 200px;
    height: 200px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto;
}

.win-datepicker-date {
    order: 0;
}
.win-datepicker-month {
    order: 1;
}
.win-datepicker-year {
    order: 2;
}